import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/Home';
import Navbar from './components/Navbar';
import Api from './components/api/Api';
import Courses from './components/Courses';
import Modules from './components/core/Modules';
import Footer from './Footer';
import Profile from './components/Profile';
import NotFound from './components/NotFound';
import Contact from './components/Contact';
import Pricing from './components/Pricing';
import About from './components/About';
import Products from './components/Products';
import PreRelease from './components/core/PreRelase';
import PreReleaseLanding from './components/core/PreReleaseLanding';

const isDevMode = process.env.REACT_APP_DEV_MODE;
const isPreRelease = process.env.REACT_APP_IS_PRE_RELEASE;

function App() {
  return (
    <div className="bg-gray-100 min-h-screen">
      
      <Navbar />
        <div>
          <Routes>
            {isPreRelease === "true"? (
              <>
                <Route path="/" element={<PreReleaseLanding />} />
                <Route path="*" element={<PreRelease />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/pricing/*" element={<Pricing />} />
                <Route path="/products/*" element={<Products />} />
                <Route path="/courses/*" element={<Courses />} />
                <Route path="/modules/*" element={<Modules />} />
                <Route path="*" element={<NotFound />} />
              </>
            )}
          </Routes>
        </div>
        <Footer />
    </div>
  );
}


export default App;
