import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { ApiClient } from "../client/ApiClient";
import CheckoutForm from "./CheckoutForm";
import { useAuth0 } from "@auth0/auth0-react";
import CheckoutFormLoading from "./CheckoutFormLoading";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;

const stripePromise = loadStripe(stripeKey || "");
const apiClient = new ApiClient(`${process.env.REACT_APP_API_URI}/v1`);



export default function Checkout() {
    const { price_id } = useParams<{ price_id: string }>();

    const [clientSecret, setClientSecret] = useState("");
    const [isApiLoading, setIsApiLoading] = useState<boolean>(true);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
      console.log("inside checkout")
        async function fetchData() {
            try {
                const token = await getAccessTokenSilently();
                const customer_id = await apiClient.getCustomerId(token)
                if(price_id === undefined) {
                  return
                }
                const subscription = await apiClient.createSubscriptions(price_id, customer_id['customer_id'])
                setClientSecret(subscription.clientSecret)
            } catch (error) {
              console.error(error);
            } finally {
              setIsApiLoading(false);
            }
        }
        fetchData();
      }, [getAccessTokenSilently, price_id]);

      return (
        <div>
          {isApiLoading || !clientSecret ?  
          <CheckoutFormLoading/>
          : (
            <div>
            {clientSecret && <Elements options={{
                clientSecret,
              }} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>}
            </div>
          )}
        </div>
      )
          }
