import { useAuth0 } from "@auth0/auth0-react";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import LoginButton from "./Login";
import { Link } from "react-router-dom";


const ProfileDropdown = () => {
  const {isAuthenticated, logout, user, isLoading } = useAuth0();
  
  if(isLoading) {
    return <div className="ml-3 relative">
          <div className="h-8 w-8 rounded-full bg-gray-300"></div>
    </div>
  }

  if (!isAuthenticated) {
    return <LoginButton />
  }

  return <Menu as="div" className="ml-3 relative">
  <div>
  <Menu.Button className="bg-gray-800 flex text-sm rounded-full ">
      <span className="sr-only">Open user menu</span>
      <img
      referrerPolicy="no-referrer"
      className="h-8 w-8 rounded-full"
      src={user?.picture}
      alt={user?.name}
      />
  </Menu.Button>
  </div>
  <Transition
  as={Fragment}
  enter="transition ease-out duration-100"
  enterFrom="transform opacity-0 scale-95"
  enterTo="transform opacity-100 scale-100"
  leave="transition ease-in duration-75"
  leaveFrom="transform opacity-100 scale-100"
  leaveTo="transform opacity-0 scale-95"
  >
  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
      <Menu.Item>
      {({ active }) => (
          <Link to="/profile" >
          <a
          href="a"
          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
          >
          Your Profile
          </a>
          </Link>
      )}
      </Menu.Item>
      <Menu.Item>
      {({ active }) => (
          <a
          href="b"
          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
          >
          Settings
          </a>
      )}
      </Menu.Item>
      <Menu.Item>
      {({ active }) => (
          <a onClick={() => logout({ returnTo: window.location.origin })}
          href={window.location.origin}
          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
          >
          Sign out
          </a>
      )}
      </Menu.Item>
  </Menu.Items>
  </Transition>
</Menu>
};

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default ProfileDropdown;
