import { Lesson, Module, Video, Course, Roles, CustomerId, Subscription } from "./models";

export class ApiClient {
    private baseUrl: string;
  
    constructor(baseUrl: string) {
      this.baseUrl = baseUrl;
    }

    async getVideos(token?: string | null): Promise<Video[]> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/videos`, {
        headers,
      });
      return await response.json();
    }
    
    async getVideo(id: number, token?: string | null): Promise<Video> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/videos/${id}`, {
        headers,
      });
      return await response.json();
    }
    
    async createVideo(video: Video, token?: string | null): Promise<Video> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/videos`, {
        method: "POST",
        headers,
        body: JSON.stringify(video),
      });
      return await response.json();
    }
    
    async updateVideo(id: number, video: Video, token?: string | null): Promise<Video> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/videos/${id}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(video),
      });
      return await response.json();
    }
    
    async deleteVideo(id: number, token?: string | null): Promise<void> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      await fetch(`${this.baseUrl}/videos/${id}`, {
        method: "DELETE",
        headers,
      });
    }
    
    async getLessons(token?: string | null): Promise<Lesson[]> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/lessons`, {
        headers,
      });
      return await response.json();
    }

    async getLesson(id: number, token?: string | null): Promise<Lesson> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/lessons/${id}`, {
        headers,
      });
      return await response.json();
    }

    async createLesson(lesson: Lesson, token?: string | null): Promise<Lesson> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/lessons`, {
        method: "POST",
        headers,
        body: JSON.stringify(lesson),
      });
      return await response.json();
    }

    async updateLesson(id: number, lesson: Lesson, token?: string | null): Promise<Lesson> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/lessons/${id}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(lesson),
      });
      return await response.json();
    }

    async deleteLesson(id: number, token?: string | null): Promise<void> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      await fetch(`${this.baseUrl}/lessons/${id}`, {
        method: "DELETE",
        headers,
      });
    }

    async createSubscriptions(priceId: string, customerId: string ): Promise<Subscription> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };  
      const response = await fetch(`${this.baseUrl}/subscriptions`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          priceId: priceId,
          customerId: customerId
        }),
      });

      return await response.json();
    }

    async getCustomerId(token: string): Promise<CustomerId> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };  
      headers.Authorization = `Bearer ${token}`;

      const response = await fetch(`${this.baseUrl}/me/customer`, {
        method: "POST",
        headers,
      });

      return await response.json();
    }



    async getModules(token?: string | null): Promise<Module[]> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/modules`, {
        headers,
      });
      return await response.json();
    }
  
    async getModule(id: string, token?: string | null): Promise<Module> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/modules/${id}`, {
        headers,
      });
      return await response.json();
    }

    async getModulePreview(id: string): Promise<Module> {
      const headers: HeadersInit = {};
      const response = await fetch(`${this.baseUrl}/modules/${id}/preview`, {
        headers,
      });
      return await response.json();
    }
  
    async createModule(module: Module, token?: string | null): Promise<Module> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/modules`, {
        method: "POST",
        headers,
        body: JSON.stringify(module),
      });
      return await response.json();
    }
  
    async updateModule(id: string, module: Module, token?: string | null): Promise<Module> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/modules/${id}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(module),
      });
      return await response.json();
    }
  
    async deleteModule(id: string, token?: string | null): Promise<void> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      await fetch(`${this.baseUrl}/modules/${id}`, {
        method: "DELETE",
        headers,
      });
    }

    async getCourses(): Promise<Course[]> {
      const headers: HeadersInit = {};
      const response = await fetch(`${this.baseUrl}/courses`, {
        headers,
      });
      return await response.json();
    }

    async getCurrentUser(token: string): Promise<Roles> {
      const headers: HeadersInit = {};
      headers.Authorization = `Bearer ${token}`;
      
      const response = await fetch(`${this.baseUrl}/me`, {
        headers,
      });
      return await response.json();
    }

    async deleteSubscription(token: string): Promise<void> {
      const headers: HeadersInit = {};
      headers.Authorization = `Bearer ${token}`;
      
      await fetch(`${this.baseUrl}/me/subscriptions`, {
        method: "DELETE",
        headers,
      });
    }

    async getCurrentUserRoles(token: string): Promise<Roles> {
      const headers: HeadersInit = {};
      headers.Authorization = `Bearer ${token}`;
      
      const response = await fetch(`${this.baseUrl}/me/roles`, {
        headers,
      });
      return await response.json();
    }

    async getCurrentUserIsPremium(token: string): Promise<boolean> {
      const data = await this.getCurrentUserRoles(token)
      return data.roles.includes("Premium");
    }

    async grantCurrentUserPremiumAcess(token: string): Promise<Roles> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/me/roles/premium`, {
        method: "POST",
        headers,
      });
      return await response.json();
    }
  
    async getCourse(id: string, token: string): Promise<Course> {
      const headers: HeadersInit = {};
      headers.Authorization = `Bearer ${token}`;
      const response = await fetch(`${this.baseUrl}/courses/${id}`, {
        headers,
      });
      return await response.json();
    }

    async getCoursePreview(id: string): Promise<Course> {
      const headers: HeadersInit = {};
      const response = await fetch(`${this.baseUrl}/courses/${id}/preview`, {
        headers,
      });
      return await response.json();
    }
  
    async createCourse(course: Course, token?: string | null): Promise<Course> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/courses`, {
        method: "POST",
        headers,
        body: JSON.stringify(course),
      });
      return await response.json();
    }
  
    async updateCourse(course: Course, token?: string | null): Promise<Course> {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${this.baseUrl}/courses/${course.id}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(course),
      });
      return await response.json();
    }
  
    async deleteCourse(id: string, token?: string | null): Promise<void> {
      const headers: HeadersInit = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      await fetch(`${this.baseUrl}/courses/${id}`, {
        method: "DELETE",
        headers,
      });
    }
    
  }
  