import { useAuth0 } from "@auth0/auth0-react"
import { RadioGroup } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"
import { Route, Routes } from "react-router-dom"
import { ApiClient } from "../client/ApiClient"
import Checkout from "./Checkout"
import classNames from "./utils"

const frequencies = [
    { value: 'annually', label: 'Annually', priceSuffix: '/month', price: "$25", discount: true},
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month', price: "$79", discount: false },
  ]

export default function Pricing() {
    return(
        <Routes>
            <Route path="" element={<PricingOptions />} />
            <Route path="checkout/:price_id" element={<Checkout />} />
        </Routes>
    )
}

const apiClient = new ApiClient(`${process.env.REACT_APP_API_URI}/v1`);

function PricingOptions() {
    const [frequency, setFrequency] = useState(frequencies[0])
    const [isApiLoading, setIsApiLoading] = useState<boolean>(true);
    const [isUserPremium, setIsUserPremium] = useState<boolean>(false);
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const {loginWithRedirect, isLoading } = useAuth0();


    useEffect(() => {
        
        async function fetchData() {
            try {
                if(isAuthenticated) {
                    const token = await getAccessTokenSilently()
    
                    const isUserPremium = await apiClient.getCurrentUserIsPremium(token);
                    console.log(isUserPremium)
                    setIsUserPremium(isUserPremium);
                } else {
                    setIsUserPremium(false);
                }
            } catch (error) {
            console.error(error);
            } finally {
            setIsApiLoading(false);
            }
        }
    
        fetchData();
        
    
        }, [getAccessTokenSilently, isAuthenticated, isUserPremium]);

    return (
        <div>
            {isApiLoading || isLoading ?  
            <div className="bg-gray-800 py-16 tablet:py-32 ">
                <div className="mx-auto max-w-7xl px-6 laptop:px-8 py-36">

                </div>
            </div>
            :
            <div className="bg-gray-800 py-16 tablet:py-32 ">
            <div className="mx-auto max-w-7xl px-6 laptop:px-8">
            <div>
              <div className="mx-auto max-w-4xl text-center">
                {isUserPremium ? <p className="mt-2 text-4xl font-bold tracking-tight text-white phone:text-5xl">
                    Enjoy your premium access!
                </p> : <p className="mt-2 text-4xl font-bold tracking-tight text-white phone:text-5xl">
                    Unlock your future career.
                </p>}
              </div>
              {isUserPremium ? <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
                Access to all courses, modules, videos and more.
              </p> : <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
                Get access to all courses, modules, videos and more.
              </p>}
              
              {isUserPremium ? 
               <div className="mt-10 flex items-center justify-center gap-x-6 py-2">
               <a
                   href="/courses"
                   className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                   Start learning
               </a>
               
             </div>
                :   <div>
                        <div className="mt-8 flex justify-center">
                            <RadioGroup
                            value={frequency}
                            onChange={setFrequency}
                            className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
                            >
                            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                            {frequencies.map((option) => (
                                <RadioGroup.Option
                                key={option.value}
                                value={option}
                                className={({ checked }) =>
                                    classNames(checked ? 'bg-indigo-500' : '', 'cursor-pointer rounded-full px-2.5 py-1')
                                }
                                >
                                <span>{option.label}</span>
                                </RadioGroup.Option>
                            ))}
                            </RadioGroup>
                        </div>
                        <div className="isolate mx-auto mt-10 grid max-w-sm grid-cols-1 gap-8   ">
                            <div
                                className="bg-white/5 ring-2 ring-indigo-500 rounded-3xl p-8 desktop:p-10">
                                <div className="flex items-center justify-between gap-x-4">
                                <h3 className="text-lg font-semibold leading-8 text-white">
                                    Full Access
                                </h3>
                                {frequency.discount ? (
                                    <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                                    Save 68%
                                    </p> ) : null}
                                </div>
                                <p className="mt-4 text-sm leading-6 text-gray-300">Unlock all of the content we have to offer.</p>
                                <p className="mt-6 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold tracking-tight text-white">{frequency.price}</span>
                                <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                                </p>
                                <ul className="mt-6 space-y-3 text-sm leading-6 text-gray-300 ">
                                    <li className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                    3 Courses
                                    </li>
                                    <li className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                    14 Modules
                                    </li>
                                    <li className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                    42 Lessons
                                    </li>
                                </ul>
                                {isAuthenticated ? <a href="pricing/checkout/price_1NWpqPEi78tqDqbyc6kvObD7" key="1">
                                    <div
                                    className="bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                    >
                                    Buy plan
                                    </div>
                                </a> :
                                     <a onClick={() => loginWithRedirect({redirectUri: "https://getskilltree.io/pricing"})} href="/" className="bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                                    Join now
                                    
                                </a>}
                            </div>
                            
                        </div>
                    </div>}
                    </div>
            </div>
          </div>
            }

        </div>

    )
    }
