import { useAuth0 } from '@auth0/auth0-react';
import { ShareIcon, CpuChipIcon, ChevronRightIcon, SignalIcon, RectangleGroupIcon } from '@heroicons/react/20/solid'


const features = [
  {
    name: 'Artificial Intelligence',
    description:
      'Large language models, computer vision, and machine learning. Learn the fundamentals of AI used by industry experts.',
    icon: ShareIcon,
  },
  {
    name: 'Distributed Systems',
    description:
      'Consistency, availability, and scalability. Explore how software works at the largest scales.',
    icon: RectangleGroupIcon,
  },
  {
    name: 'Embedded Systems',
    description:
      'Interested in pushing software to its physical limits? Take our embedded systems crash course.',
    icon: CpuChipIcon,
  },
  {
    name: 'Networking',
    description:
      'The central nervous system of the internet. Discover how your 0s and 1s move across the wire (and when they dont).',
    icon: SignalIcon,
  },
]
  
export default function PreReleaseLanding() {
  const {loginWithRedirect } = useAuth0();

  return (
    <div>
    <div className="relative isolate overflow-hidden bg-gray-800">
      <svg
        className="invisible laptop:visible absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
      </svg>
      <div className="mx-auto w-full px-2 tablet:px-8 desktop:px-20 pb-24 phone:pb-32  laptop:flex  laptop:py-24">
        <div className="mx-auto  pt-10 max-w-2xl flex-shrink-0 laptop:mx-0 laptop:max-w-xl laptop:pt-20">
      <div className=" w-full flex justify-center laptop:justify-start">

      
        <a onClick={() => loginWithRedirect({redirectUri: "https://getskilltree.io/waitlist"})} href="/" className="inline-flex  space-x-6">
              <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">
                Under construction
              </span>
              <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                <span>Coming September 2023</span>
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </span>
            </a>
            </div>

          <h1 className="mt-10 text-center laptop:text-left text-4xl font-bold tracking-tight text-white phone:text-6xl">
          Prepare to land your dream job.
          </h1>
          <p className="mt-6 mb-2 text-center laptop:text-left text-lg leading-8 text-gray-300">
          By learning from the engineers that work there.

          </p>
          <div className="flex items-center">
          <div className="mt-6 grid items-center gap-x-4 gap-y-10  laptop:mx-0 laptop:max-w-none grid-cols-4">
                <img
                    className="max-h-12 ml-2 phone:pl-8 laptop:pl-0 w-full object-contain col-span-1"
                    src="/images/spacex.png"
                    alt="SpaceX"
                    width={158}
                    height={48}
                />
                <img
                    className=" col-span-1 ml-2 phone:ml-4 laptop:ml-0 max-h-12 w-full object-contain "
                    src="/images/pltr.png"
                    alt="Palantir"
                    width={158}
                    height={48}
                />
                
                <img
                    className="col-span-1 ml-2 max-h-12 phone:ml-4 laptop:ml-0 w-full object-contain"
                    src="/images/apple.png"
                    alt="Apple"
                    width={158}
                    height={48}
                />

                <img
                className="col-span-1 ml-2 phone:ml-6 laptop:ml-0 col-start-auto max-h-12 w-full object-contain "
                src="/images/tesla.png"
                alt="Tesla"
                width={158}
                height={48}
            />
               
                </div>
          </div>
                
          <div className="mt-10 flex justify-center laptop:justify-start items-center gap-x-6">
              <a
                onClick={() => loginWithRedirect({redirectUri: "https://getskilltree.io/waitlist"})} href="/"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Join the waitlist
            </a>
            
          </div>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl phone:mt-24 laptop:ml-10 laptop:mr-0 laptop:mt-0 laptop:max-w-none laptop:flex-none desktop:ml-32">
          <div className="max-w-3xl flex-none phone:max-w-5xl laptop:max-w-none">
            <img
              src="/images/Course-10.png"
              alt="App screenshot"
              width={2432}
              height={1442}
              className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
            />
          </div>
        </div>
      </div>
    </div>
    <Feature/>
    <CTA/>
    </div>
  )
}


export function Feature() {
  return (
    <div className="bg-gray-800 py-8 phone:py-8">
    <div className="mx-auto w-full flex justify-center px-2 tablet:px-8 desktop:px-20">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 phone:gap-y-20 laptop:mx-0 laptop:max-w-none laptop:grid-cols-3">
        <div>
        <h2 className="text-3xl text-center laptop:text-left font-bold tracking-tight text-white phone:text-4xl">
          Covering the concepts that others miss
        </h2>
        <p className="mt-6 mb-2 text-center laptop:text-left text-lg leading-8 text-gray-300">
          From Computer Architecture to LLMs, we go beyond traditional interview prep.
          </p>
          </div>
        <dl className="col-span-2 px-6 grid grid-cols-1 gap-x-8 gap-y-16 phone:grid-cols-2">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt className="text-base font-semibold leading-7 text-white">
                <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                {feature.name}
              </dt>
              <dd className="mt-1 text-base leading-7 text-gray-300">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>)
}

export function CTA() {
  const {loginWithRedirect } = useAuth0();

  return (
    <div className="bg-gray-800">
      <div className="mx-auto  w-full px-2 tablet:px-8 desktop:px-20 py-24 phone:py-32 laptop:flex laptop:items-center laptop:justify-between ">
        <h2 className="text-3xl text-center laptop:text-left font-bold tracking-tight text-white phone:text-4xl">
          Interested in gaining an edge?
          <br />
          Join our waitlist today!
        </h2>
        <div className="mt-10 flex justify-center laptop:justify-start items-center gap-x-6 laptop:mt-0 laptop:flex-shrink-0">
          <a
            onClick={() => loginWithRedirect({redirectUri: "https://getskilltree.io/waitlist"})} href="/"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Join now
          </a>
          
        </div>
      </div>
    </div>
  )
}
