import { useAuth0 } from "@auth0/auth0-react";

export default function PreRelease() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  
    return (
        <div className="bg-white min-h-fit">
        <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
          <img
            className="mx-auto h-10 w-auto sm:h-12"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Skilltree"
          />
          <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
            {
              !isAuthenticated || isLoading ? <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Interested in learning more?</h1>
              : <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Thank you for joining the waitlist!</h1>
            }
             {
              !isAuthenticated || isLoading ?
            <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
             Join our waitlist!
            </p> : <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
              We will be in touch as we approach our launch date.
            </p>}
          </div>
          <div className="mx-auto mt-8 flow-root max-w-lg sm:mt-20">
            
            <div className="flex justify-center">
            {
              !isAuthenticated || isLoading ?
              <a onClick={() => loginWithRedirect({redirectUri: "https://getskilltree.io/waitlist"})} href="/" className="text-sm font-semibold leading-6 text-indigo-600">
                Join now
              </a> : <a href="/" className="text-sm font-semibold leading-6 text-indigo-600">
                Back to landing
              </a> }
            </div>
          </div>
        </main>
      </div>
    );
    }


