import { Link } from "react-router-dom"
import ProfileDropdown from "./ProfileDropdown"

import { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from "./utils"



export default function Navbar() {
  const [navigation, setNavigation] = useState(
    [
        { name: 'Products', href: '/products', current: window.location.pathname === '/products' },
        { name: 'Profile', href: '/profile', current: window.location.pathname === '/profile' },
        { name: 'Pricing', href: '/pricing', current: window.location.pathname === '/pricing' },
        { name: 'About', href: '/about', current: window.location.pathname === '/about' },
        { name: 'Contact', href: '/contact', current: window.location.pathname === '/contact' },
      ]
);

  return (
    <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="mx-auto w-full px-2 tablet:px-8 desktop:px-20">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center tablet:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center tablet:items-stretch tablet:justify-start">
                <div className="flex flex-shrink-0 items-center">
                <Link
                      to="/"
                      key="home"
                      onClick={() => setNavigation([
                        { name: 'Products', href: '/products', current: false },
                        { name: 'Profile', href: '/profile', current: false },
                        { name: 'Pricing', href: '/pricing', current: false },
                        { name: 'About', href: '/about', current: false },
                        { name: 'Contact', href: '/contact', current: false },
                      ])}
                    >
                  <img
                    className="block h-8 w-auto desktop:hidden"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-8 w-auto desktop:block"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    alt="Your Company"
                  />
                  </Link>
                </div>
                <div className="hidden tablet:ml-6 tablet:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                      to={item.href}
                      key={item.name}
                      onClick={() => setNavigation([
                        { name: 'Products', href: '/products', current: item.href === '/products' },
                        { name: 'Profile', href: '/profile', current: item.href === '/profile' },
                        { name: 'Pricing', href: '/pricing', current: item.href === '/pricing' },
                        { name: 'About', href: '/about', current: item.href === '/about' },
                        { name: 'Contact', href: '/contact', current: item.href === '/contact' },
                      ])}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white text-base' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'rounded-md px-3 py-2 text-base font-medium'
                      )}
                    >
                      {item.name}
                    </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 tablet:static tablet:inset-auto tablet:ml-6 tablet:pr-0">
                <ProfileDropdown/>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="tablet:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                 <Link
                 reloadDocument={true}
                 to={item.href}
                 key={item.name}
                 onClick={() => setNavigation([
                   { name: 'Products', href: '/products', current: item.href === '/products' },
                   { name: 'Profile', href: '/profile', current: item.href === '/profile' },
                   { name: 'Pricing', href: '/pricing', current: item.href === '/pricing' },
                   { name: 'About', href: '/about', current: item.href === '/about' },
                   { name: 'Contact', href: '/contact', current: item.href === '/contact' },

                 ])}
                 className={classNames(
                  item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                 )}
               >
                 {item.name}
               </Link>
                
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
