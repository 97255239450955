import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const {loginWithRedirect } = useAuth0();
  
  return <button onClick={() => loginWithRedirect()}
  type="button"
  className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
  >
  Log In
</button>
  
  
};

export default LoginButton;
